<template>
  <div class="about">
    DB-Connections:
    <table border=1>
    <tr><td>App</td><td>Datname</td><td>UseName</td><td>Status</td><td>SQL</td></tr>
    <tr v-for="(x,idx) in data" :key="'k'+idx">
    <td>{{x.app}}</td><td>{{x.datname}}</td><td>{{x.usename}}</td><td>{{x.state}}</td><td>{{x.query}}</td>
    </tr>
    </table>
    <button @click='getStatus()'>Reload</button>
	<InputText entity="product" subTyype="" searchImmed='4' border="false" v-model="test"/>
  </div>
</template>
<script>
// import axios from 'axios';

import { HTTP, serviceAPI,showError } from '@/variables'
import InputText from '@/components/inputElements/InputTextWithSearch';
export default {
  name: 'heartbeat',
  components : {
	InputText
  },
  data () { 
    return {
        members: [],
		test: "",
      	data: {},
      }
    },
  created()
  {
    this.getStatus();
  }, 
    computed: {
   
   },
  watch:
  {
    
  },
  methods: {
     getStatus()
     {
         let that = this;
         //alert( serviceAPI+"/getDBConnections/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
         HTTP.post( serviceAPI+"/getDBConnections/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
            .then( response => 
            { 
                //alert( JSON.stringify(response.data));
                that.data = response.data.filter(p=>p.app);
                
            }).catch(e => {
                  showError( that.$toast, "can't get server status:", e)
            });
     },
     getMembers()
     {
         let that = this;
         //alert( serviceAPI+"/getDBConnections/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
         HTTP.post( serviceAPI+"/getMembers/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
            .then( response => 
            { 
                //alert( JSON.stringify(response.data));
                that.members = response.data;
                
            }).catch(e => {
                  showError( that.$toast, "can't get server status:", e)
            });
     }
       
    }
}
</script>
<style lang="scss" scoped>

</style>