<template>
<div class='inputWrapper' :style="getAppStyle()+'width:'+width+'% !important;'">
  
	<span class='span100' v-if="!options || !options.length">
		<span style="display: inline-flex; width: 80% !important;">
		<input ref='inp' :class="border?'ieInputBorder':'ieInputTextNBNC'" :style='getStyle()' 
		    :disabled=disabled :autocomplete="autoCompletee"
	    	@change="changed" 
	    	@keypress.enter="myId?enterPressed():startSearch()" 
	    	@keyup.esc="escapePressed()"
	    	@blur="$emit('blur')" 
	    	@keyup="keydown()"
	    	@focus="$event.target.select()" v-model="value"/>
	   </span>
			<span v-if="withDelete && options" style="float: right; width: 50pt;">
				<button @click="startSearch()" :style="getAppStyle()" class="mbutton"><mdicon size="16" name="magnify"/></button>
				<button @click="value=''; options=[]; $emit('id', 0)" :style="getAppStyle()+'float: bottom;'" class="mbutton"><mdicon size="16" name="close"/></button>
			</span>
			<span v-else style="float: right; width: 20pt;">
				<button @click="startSearch()" :style="getAppStyle()" class="mbutton"><mdicon size="16" name="magnify"/></button>
			</span>
	</span>
	<span class='span100 dontWrap' style="display: flex; float: top;" v-else>
		<span style="display: inline-flex; width: 120px; !important; float: bottom;">
		<select :style="getAppStyle()+'width: 120px; height: 21pt;'" v-model="myId" @change="changeId">
			<option v-for="(o,oi) in options" :key="'o'+oi" :value="o.id">{{o.label}}</option>
		</select>	
		</span>
		<span style="float: right; width: 20pt;">
			<button @click="options=[]" :style="getAppStyle()+'float: bottom;'" class="mbutton"><mdicon size="16" name="close"/></button>
		</span>
		
	</span>	

</div>
</template>
<script>

import { HTTP, fwAPI } from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import GSelectSimple from '@/components/GSelectSimple';

export default {
  props: {
    value: String,
    fontSize: { type: Number, default: 14},
    disabled: Boolean,
	autoComplete: { type: String, default: "one-time-code"},
    width:  { type: Number, default: 100 },
    mark: { type: Boolean, default: false},
	withDelete: { type: Boolean, default: false},
    entity: { type: String, default: 'Client'},
	subType: { type: String, default: 'Advertiser'},
	searchImmed: { type: Number, default: 3},
    border: { type: Boolean, default: false}
  },
  data() {
      return {
      	copy: null,
		options: [],
      	init: true,
      	doSearch: false,
		myId: 0,
      	getAppStyle
      }
  }, 
  components : {
	
  },
  methods: {
      open() { this.$refs.inp.focus(); this.$refs.inp.select();},
      getStyle() { 
      	if ( this.mark )
      	{
      		return "font-size:" +this.fontSize+"px; background-color: #fdd;";
      	}
      	return "font-size:" +this.fontSize+"px;"+getAppStyle();

      },
      save()  {
      	if ( this.init ) { this.copy = this.value; this.init = false }
      },
      fire()
      {
    	  if ( this.search)
    	  {
    		  this.$emit('search');
			  
    	  }
    	  else
    	  {
    		  this.$emit('enter');
			  this.$emit('id', this.myId);
    	   }
      },
      enterPressed() { this.fire();   },
	  keydown() 
	  { 
		if ( this.searchImmed && this.value && this.value.length >= this.searchImmed) 
		{ 
			this.startSearch() 
		} 
	  },
      escapePressed() { this.$emit("input", this.copy); this.$emit("escape"); },
      changed() { this.$emit("changed", this.value); },
	  changeId(  )
	  {
		
		this.value = this.options.find( p => p.id==this.myId).label;
		
		this.options=[];
		if ( this.value )
		{
			this.$emit('input', this.value);
			this.$emit('id', this.myId);
			this.$emit('enter');
		}
	  },
	  startSearch()
	  {
		let that = this;
		let filter = { name: this.value }
		HTTP.post( fwAPI+"/getAllQ/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.entity+"/"+this.subType, filter)
          .then( response => 
          { 
			
				that.options = response.data.map(p=>{ return { id: p.id, label: p.name, visible:true}});
				if ( that.options.length == 1)
				{
					this.value = this.options[0].label;
					this.myId = this.options[0].id;
					this.$emit('input', this.value);
					this.$emit('enter');
					this.options=[];
					return;
				}
				that.options.push({ id: 0, label: "", visible:true})
				that.myId = 0; //that.options.length ? that.options[0].id: 0;
		   })
		   .catch(e => {
		             console.log(JSON.stringify( e));
		 });
	  }
  },
  created() {
    //this.copy = ""+this.value; 
    
    
  },
  mounted() {
    //this.copy = ""+this.value; 
    this.open();
    
  },
  watch: {
	value: function() { 
		this.$emit('input', this.value);
		this.$emit('search');
		this.$emit('id', this.myId);
		}
  }
 }
</script>
<style lang="scss" scoped>
input { border: none; width: 100%}
input:disabled { font-size: 12pt; border: none; width: 100%; color: #888 !important; }
input:focus { outline: 1pt dotted grey;}

.ieInputText { text-align: left; height: 100%; margin: 0; padding: 0; border: none; }
.ieInputTextNBNC { text-align: left; height: 20pt; margin: 0; padding: 0; border: none; }
.ieInputBorder { padding: 2pt; text-align: left; border: 1pt solid #ccc;  }
.span100 { display: inline-block; width: 100%; height: 100%;}
.span100 { display: inline-block; width: 100%; height: 100%;}
.span25 { display: inline-flex; width: 85%; height: 100% !important; }
.spanSX { display: inline-flex; width: 85%; height: 100% !important; }
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    display: inline-block;
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    background-color: transparent !important;
}
.mbutton {
	height: 20pt;
	border: 1px solid #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: block;
}
.inputButton { 
    border: 1pt solid grey;
}

</style>